.wrapper {
  justify-content: center;
}

.additionalNotes {
  width: 100%;
  display: flex;
  justify-content: space-around;
  height: 48px;
  align-items: center;
  margin: 16px 0;
}

.registerButton {
  width: 100%;
  text-align: center;
}
