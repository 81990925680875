@import '~antd/dist/antd.css';

.App {
  text-align: center;
  color: #282c34;
}

.site-layout-sub-header-background {
  background: #f0f2f5;
}

.site-layout-background {
    margin: 24px;
    overflow: initial;
}
.ant-input-affix-wrapper {
    border-radius: 4px;
    height: 48px;
    line-height: 48px;
}

.clickable-table-row {
    cursor: pointer;
}

.table-row-is-main-account {
    background-color: #e6fffb;
}

.jobs-table {
    padding-bottom: 20px;
}

.mb20 {
    margin-bottom: 20px;
}


/* fix bug Some icons are not centered https://github.com/ant-design/ant-design/issues/13074 */
svg {
    vertical-align: baseline;
}