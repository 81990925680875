.wrapper {
  text-align: center;

  > img {
    margin-right: 32px;

    @media only screen and (max-width: 768px) {
      width: 256px;
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
