*{margin:0; padding:0; box-sizing:border-box; outline:none;}


/*ANT CUSTOMISATION START*/
.landing-layout {
	background: #30384e;
	color: #fff;
	font-family: 'Vela Sans Regular';
    font-size: 17px;
    line-height: 23px;
}

.landing-layout h2.faded.active {
	color: #fff
}

.landing-layout .ant-collapse.questionCollapse {
	font-size: 18px;
    line-height: 19px;
	background-color: #30384e;
	color: #fff;
}
.landing-layout .ant-collapse.questionCollapse>.ant-collapse-content {
	background-color: #2f54eb;
}

.landing-layout div.questions .faq .display {
	display: block;
}

.landing-layout .bluebtn {
	color:#fff;
	display:block;
	font-size: 18px;
	line-height:58px;
	height:58px;
	padding:0 50px;
	margin:0 30px 0 0;
	border-radius: 10px;
	border:none;
	cursor:pointer;
	font-weight:normal;
}
.landing-layout .ant-col-offset-8 {
	margin-left: 0;
}
.landing-layout .ant-form-item {
	margin-bottom: 0;
}

.landing-layout .screen2 .block2 .description b {
	padding-left: 12px;
}

/*ANT CUSTOMISATION END*/

@font-face {
    font-family: 'Igra Sans Regular';
    src: url('../fonts/IgraSans.eot');
    src: url('../fonts/IgraSans.eot?#iefix') format('embedded-opentype'),
    url('../fonts/IgraSans.woff') format('woff'),
    url('../fonts/IgraSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }
	
@font-face {
    font-family: 'Vela Sans Regular';
    src: url('../fonts/VelaSans-Regular.woff') format('woff'),
    url('../fonts/VelaSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }	
@font-face {
    font-family: 'Vela Sans Medium';
    src: url('../fonts/VelaSans-Medium.woff') format('woff'),
    url('../fonts/VelaSans-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }	
@font-face {
    font-family: 'Vela Sans Light';
    src: url('../fonts/VelaSans-Light.woff') format('woff'),
    url('../fonts/VelaSans-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    }	

@keyframes fadeinup {
    from { opacity: 0; transform:translate(0, 20px);}
    to   { opacity: 1; transform:translate(0, 0px);}
}
@keyframes fadein {
    from { opacity: 0;}
    to   { opacity: 1;}
}
	
body .landing-layout{background:#30384e; font-family: 'Vela Sans Regular'; font-size: 17px; line-height:23px; color:#fff}
.landing-layout img{display:block}
.landing-layout a{color:#fff; text-decoration:none}

.landing-layout .wrapper{width:1164px; margin:0 auto}

.landing-layout .header{display:flex; justify-content:space-between; padding:60px 0 30px 0; align-items:center}
.landing-layout .header .enter{background:url('../img/enter.png') left center no-repeat; padding:0 0 0 25px; font-family: 'Vela Sans Medium'; font-size: 20px; line-height:24px;}
.landing-layout .header .products{padding:0 0 0 25px; font-family: 'Vela Sans Medium'; font-size: 20px; line-height:24px; z-index:101;}

.landing-layout .topblock{display:flex; justify-content:space-between;}
.landing-layout .topblock>div{width:50%}
.landing-layout .topblock .title{font-family: 'Igra Sans Regular'; font-size: 47px; line-height: 53px;}
.landing-layout .topblock.faded .title{animation: fadein 2s;}
.landing-layout .topblock .description{font-size: 18px; line-height: 24px; color: #A6A6A6; padding:17px 0 30px 0}
.landing-layout .buttons{display:flex}
.landing-layout .button{display:flex; justify-content:center}
.landing-layout .topblock .image{background:url('../img/puzzle1.png') top right no-repeat; background-size:contain}
.landing-layout .topblock .image img{margin:-80px 0}
.landing-layout .topblock.faded .redbtn{animation: fadeinup 2s .2s backwards;}
.landing-layout .topblock.faded .whitebtn{animation: fadeinup 2s .4s backwards;}
.landing-layout .topblock.faded .image{animation: fadein 2s .1s backwards;}
.landing-layout .topblock.faded .image img{animation: fadein 2s .2s backwards;}

.landing-layout .redbtn, .whitebtn, .bluebtn{color:#fff; display:block; font-size: 18px; line-height:58px; height:58px; padding:0 50px; margin:0 30px 0 0; border-radius: 10px; border:none; cursor:pointer; font-weight:normal;}
.landing-layout .redbtn{background: #FF3838; filter: drop-shadow(0px 0px 28px rgba(255, 56, 56, 0.63));}
.landing-layout .redbtn:hover, .whitebtn:hover, .bluebtn:hover {
  box-shadow: inset 0 0 20px rgba(255, 255, 255, .5), 0 0 20px rgba(255, 255, 255, .2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388; 
}
.landing-layout .whitebtn{border:1px solid #fff}
.landing-layout .bluebtn{background: #002ED1; filter: drop-shadow(0px 0px 28px rgba(1, 51, 226, 0.63));}

.landing-layout .networks .images{background: linear-gradient(180deg, #3E4569 28.1%, rgba(62, 69, 105, 0) 100%); border-radius: 10px; height:135px; padding:0 50px;
display:flex; justify-content:space-between; align-items:center}
.landing-layout .networks .title{font-size: 22px; line-height:26px; color: #727A9A; padding:0 0 22px 0; font-family: 'Igra Sans Regular';}

.landing-layout h2{padding:35px 0; font-family: 'Igra Sans Regular'; font-size: 48px; line-height:68px; font-weight:normal; opacity: 0; transform:translate(0, 20px);}
.landing-layout h2.faded{animation: fadeinup 2s forwards;}


.landing-layout .col3{display:flex; justify-content:space-between;}
.landing-layout .col3 .cart{background: #3E4569; box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1); border-radius: 20px; width:calc(33.333% - 20px); padding:30px; opacity:0}
.landing-layout .col3 .cart.active{animation: fadeinup 2s .2s forwards;}
.landing-layout .col3 .cart .title{font-family: 'Igra Sans Regular'; font-size: 28px; line-height: 34px; padding:23px 0 17px 0}

.landing-layout .screen2{background: linear-gradient(180deg, #3E4569 8.15%, rgba(62, 69, 105, 0)); padding:65px 0; margin:100px 0 0 0; overflow:hidden}
.landing-layout .screen2 .block2{display:flex; justify-content:space-between;}
.landing-layout .screen2 .block2>div{width:50%}
.landing-layout .screen2 .block2 .title{font-family: 'Igra Sans Regular'; font-size:50px; line-height:62px; opacity:0}
.landing-layout .screen2 .block2.faded .title{animation: fadeinup 2s forwards;}
.landing-layout .screen2 .block2 .description{font-size: 27px; line-height: 36px; padding:18px 0 33px 0; opacity:0}
.landing-layout .screen2 .block2 .description b{font-family: 'Igra Sans Regular'; font-size: 27px; line-height: 32px; font-weight:normal}
.landing-layout .screen2 .block2 .description b span{color:#FF3838;}
.landing-layout .screen2 .block2.faded .description{animation: fadeinup 2s .2s forwards;}
.landing-layout .screen2 .block2 .image img{margin: -70px 0 -170px 25px;}
.landing-layout .screen2 .block2 .buttons{opacity:0}
.landing-layout .screen2 .block2.faded .buttons{animation: fadeinup 2s .4s forwards;}
.landing-layout .screen2 .block2 .image, .screen2 .block2 .image img{opacity:0}
.landing-layout .screen2 .block2.faded .image{animation: fadein 2s .2s forwards;}
.landing-layout .screen2 .block2.faded .image img{animation: fadein 2s .3s forwards;}

.landing-layout .benefits{display:flex; justify-content:space-between; padding:20px 0 50px 0}
.landing-layout .benefits>div{width:calc(25% - 20px); background:url('../img/quadro.png') left top no-repeat; padding:70px 0 0 0; opacity:0}
.landing-layout .benefits>div.active{animation: fadeinup 2s .2s forwards;}

.landing-layout .questions{display:flex; justify-content:space-between;}
.landing-layout .questions>div{width:45%}
.landing-layout .questions .faq{border-bottom:1px solid #4C5270;}
.landing-layout .questions .faq .description{display:none; font-size: 14px; line-height:17px; padding:0 0 15px 0; color: #A6A6A6;}
.landing-layout .questions .faq .title{font-size: 18px; line-height:19px; border-top:1px solid #4C5270; padding:13px 50px 13px 0; position:relative; cursor:pointer}
.landing-layout .questions .faq .title:after{content:''; display:block; width:13px; height:13px; position:absolute; right:0; top:12px; border-right:1px solid #fff;
border-bottom:1px solid #fff; transform:rotate(45deg); transition:all .3s}
.landing-layout .questions .faq .title.opn:after{transform: rotate(-135deg); top:22px}

.landing-layout .questionform{background: #3E4569; border-radius: 15px; padding:50px}
.landing-layout .questionform .title{font-size: 42px; line-height: 47px; text-align: center;}
.landing-layout .questionform .description{font-size: 20px; line-height:25px; text-align: center; color: #A6A6A6; padding:30px 0}
.landing-layout .questionform input.text{background: #2A3257; border-radius: 10px; color:#B6B6B6; display:block; width:100%;
margin:0 0 25px 0!important; font-size:22px; height:65px; line-height:65px; padding:0 20px; border:none}
.landing-layout .questionform .iti{width:100%; margin:0 0 25px 0 }
.landing-layout .questionform .iti__selected-flag{padding:0 15px}
.landing-layout .iti__flag-box, .iti__country-name {color:#333}
.landing-layout .questionform input.text.phone{padding-left: 70px!important;}
	
.landing-layout .numberline{background:url('../img/numberline.png') center top repeat-x; padding:0 0 130px 0; background-size: 100% auto;}
.landing-layout .numberline .wrapper{display:flex; justify-content:center; padding:100px 0 0 0}
.landing-layout .numberline .wrapper>div{width:30%}
.landing-layout .numberline .wrapper>div:nth-child(2){padding:50px 0 0 20px}
.landing-layout .numberline .wrapper>div:nth-child(3){padding:0px 0 0 70px}
.landing-layout .numberline .title{font-size: 28px; line-height:34px; padding:0 0 8px 0}

.landing-layout .footer{font-family: 'Vela Sans Light'; background: #3E4569; font-size: 14px; line-height: 17px; margin:80px 0 0 0}
.landing-layout .footer .wrapper{display:flex; justify-content:space-between; padding:25px 0; align-items:center}
.landing-layout .footer a.under{text-decoration: underline;}
.landing-layout .footer .phone{font-family: 'Vela Sans Medium'; font-weight: 500; font-size: 18px;line-height: 25px;}

.landing-layout .calcformblock{padding:45px 0 93px 0}
.landing-layout .calcformblock .description{font-size: 26px; line-height:29px; color: #A6A6A6; margin:-7px 0 35px 0; position:relative; opacity:0}
.landing-layout .calcformblock h2.faded+.description{animation: fadein 2s .2s forwards;}

.landing-layout .calcform{display:flex; justify-content:space-between; flex-wrap:wrap}
.landing-layout .calcform>div{width:calc(33.333% - 20px)}
.landing-layout .calcform input.text{color:#fff; border-radius: 10px; display:block; width:100%; font-family: 'Vela Sans Light'; letter-spacing:.5px;
margin:0 0 25px 0; font-size:20px; height:70px; line-height:70px; padding:0 20px 0 80px; border:none}
.landing-layout .calcform input.text::placeholder{color:#fff;}
.landing-layout .calcform input.text.vk{background:#3E4569 url('../img/vk1.png') left 15px center no-repeat;}
.landing-layout .calcform input.text.inst{background:#3E4569 url('../img/inst1.png') left 15px center no-repeat;}
.landing-layout .calcform input.text.my{background:#3E4569 url('../img/my1.png') left 15px center no-repeat;}
.landing-layout .calcform input.text.tt{background:#3E4569 url('../img/tt1.png') left 15px center no-repeat;}
.landing-layout .calcform input.text.ya{background:#3E4569 url('../img/ya1.png') left 15px center no-repeat;}
.landing-layout .calcform .redbtn{font-size:20px; letter-spacing:1px; height:70px; line-height:70px;}

.landing-layout .total{display:flex; padding:35px 0 0 0; align-items: center;}
.landing-layout .total .bonus{font-family: 'Igra Sans Regular'; font-size: 32px; line-height: 32px; margin:0 50px 0 0}
.landing-layout .total .bonus .red{color:#FF3838; display:inline}
.landing-layout .total .little{font-size: 18px; line-height: 20px; color: #CBC9C9;}

@media(max-width:840px){
	
	.landing-layout .wrapper{width:100%; padding: 0 100px}
	
	.landing-layout h2{font-size: 40px; line-height: 48px; padding:30px 0}
	.landing-layout .header {padding: 30px 0 40px 0;}
	.landing-layout .topblock{flex-direction:column; text-align:center}
	.landing-layout .topblock>div {width:100%;}
	.landing-layout .topblock .title {font-size:50px; line-height: 56px;}
	.landing-layout .topblock .description {font-size: 22px; line-height: 29px; padding: 16px 0 35px 0;}
	
	.landing-layout .col3{flex-direction:column}
	.landing-layout .col3 .cart{width:100%}
	.landing-layout .col3 .cart img{float:left; margin:0 25px 0 0}
	.landing-layout .col3 .cart .title {padding: 15px 0 25px 0;}
	.landing-layout .col3 .cart{margin:0 0 25px 0}
	
	.landing-layout .screen2 {background:none; padding: 30px 0; margin:0px 0 0 0;}
	.landing-layout .screen2 .block2{align-items: center;}
	.landing-layout .screen2 .block2 .image img { margin: 0px 0 0px -80px; z-index:-1; position:relative; }
	.landing-layout .screen2 .block2 .title {font-size: 40px; line-height:49px;}
	.landing-layout .screen2 .block2 .description {font-size:22px; line-height:29px; padding: 16px 0 29px 0;}
	.landing-layout .screen2 .col3{flex-direction: row;}
	.landing-layout .screen2 .col3 .cart{width:32%; padding: 20px}
	.landing-layout .screen2 .col3 .cart .title {padding: 0px 0 10px 0; font-size:20px; line-height:26px;}
	.landing-layout .screen2 .col3 .cart .description{font-size:15px; line-height:20px;}
	
	.landing-layout .buttons{justify-content: center;}
	.landing-layout .screen2{align-items: center;}
	.landing-layout .screen2 .buttons{justify-content:flex-start; }
	.landing-layout .screen2 .buttons>a{margin:0 0}
	.landing-layout .screen2 .block2>div{width:70%}
	.landing-layout .screen2 .block2>div.image{width:30%}
	
	.landing-layout .buttons>a{margin:0 10px}

	.landing-layout .topblock .image{background-position:right top 70px}
	.landing-layout .topblock .image img {margin: 0px 0;}
	
	.landing-layout .networks{padding:0 0 40px 0}
	.landing-layout .networks .title{text-align:center; padding:20px 0 20px 0}
	.landing-layout .networks .images{justify-content: center; background:none;padding:0; flex-wrap:wrap; height:auto}
	.landing-layout .networks .images img{margin:5px 20px}
	
	
	.landing-layout .benefits{flex-wrap:wrap; padding: 15px 0 45px 0;}
	.landing-layout .benefits>div {width: calc(50% - 20px); padding: 0 0 20px 55px; background-size: 40px 40px}
	.landing-layout .benefits>div br{display:none}
	
	.landing-layout .calcformblock {padding: 60px 0;}
	.landing-layout .calcformblock .description {font-size: 22px; line-height: 24px; margin: 0px 0 35px 0;}
	.landing-layout .calcform>div {width: calc(50% - 12px);}
	.landing-layout .calcform>div:nth-child(3){display:none}
	.landing-layout .calcform .redbtn{margin:0 0}
	.landing-layout .total{flex-direction:column; padding:15px 0 0 0}
	.landing-layout .total .bonus {font-size:28px; line-height:28px; margin: 0 0 15px 0;}
	
	.landing-layout .numberline{padding:0 0 40px 0; background: url(../img/numberline2.png) center top no-repeat; background-size: contain; }
	.landing-layout .numberline .wrapper{padding: 85px 0 0 0;}
	.landing-layout .numberline .wrapper>div:nth-child(3) {padding: 0px 0 0 20px;}
	
	.landing-layout .numberline .title {font-size: 20px; line-height: 24px; padding:0 0 3px 0}
	.landing-layout .numberline .description{font-size:14px; line-height:19px;}
	.landing-layout .numberline .description br{display:none}
		
	.landing-layout .questions{flex-direction:column;}
	.landing-layout .questions>div {width:100%;}
	.landing-layout .questionform{margin:50px 0 0 0}
	.landing-layout .questionform .description {font-size: 22px;}
	
	.landing-layout .footer{margin:100px 0 0 0; text-align:center; font-size: 18px; line-height:22px}
	.landing-layout .footer .wrapper{flex-direction:column; padding: 20px 0 40px 0;}
	.landing-layout .footer .wrapper>div:nth-child(1),
	.landing-layout .footer .wrapper>div:nth-child(2){order:1}
	.landing-layout .footer .phone{font-size:24px; line-height:33px; padding:30px 0; order:2}
	.landing-layout .footer .wrapper>div:nth-child(5){order:3}
	.landing-layout .footer .wrapper>div:nth-child(3){order:4}
	.landing-layout .footer .redbtn{margin:0 0 30px 0}
	.landing-layout .redbtn, .whitebtn, .bluebtn{line-height:58px; height:58px;}
	.landing-layout .footer img{margin: 0 -30px 0 0; position: relative;}

}



@media(max-width:420px){
	.landing-layout .wrapper, .numberline .wrapper{padding:0 30px}
	.landing-layout h2{font-size: 30px; line-height: 34px;}
	.landing-layout .header {padding: 30px 0 40px 0;}
	.landing-layout .header img{height:60px}
	.landing-layout .topblock .title {font-size:30px; line-height:34px;}
	.landing-layout .topblock .description {font-size: 16px; line-height:21px; padding: 15px 0 20px 0;}
	.landing-layout .redbtn, .whitebtn, .bluebtn{line-height:46px; height:46px; padding:0 30px}
	.landing-layout .buttons>a{margin: 0 7px;}
	
	.landing-layout .topblock{overflow:hidden}
	.landing-layout .numberline .wrapper{flex-direction:column;}
	.landing-layout .benefits>div,
	.landing-layout .numberline .wrapper>div,
	.landing-layout .calcform>div{width:100%}
	.landing-layout .screen2 .col3 .cart .title{font-size:22px}
	
	.landing-layout .footer{margin:70px 0 0 0;}
	.landing-layout .questionform {padding: 25px 30px;}
	.landing-layout .questionform .title{font-size: 24px; line-height: 27px}
	.landing-layout .questionform .description{padding: 10px 0 20px 0;}
	
	.landing-layout .questionform input.text{line-height:65px; height:65px; font-size:14px; padding:0 20px; margin: 0 0 20px 0!important;}
	.landing-layout .questionform .bluebtn{width:100%; line-height:65px; height:65px;}
	
	.landing-layout .numberline {padding: 0 0 30px 0; background:none}
	.landing-layout .topblock .image img{width: 100%;}
	.landing-layout .networks .title {font-size:16px; line-height: 20px;}
	.landing-layout .networks .images>div{max-width:45%}
	.landing-layout .networks .images>div img{max-width:100%; margin:0}
	.landing-layout .col3 .cart {padding: 20px;}
	.landing-layout .col3 .cart .title {font-size: 20px; line-height: 24px;}
	.landing-layout .col3 .cart img{width:47px; height:auto; margin: 0 20px 0 0;}
	.landing-layout .screen2 .block2>div {width:100%;}
	.landing-layout .screen2 .block2>div.image {display:none}
	.landing-layout .screen2 .block2 .title {font-size:26px; line-height:32px;}
	.landing-layout .screen2 .col3 {flex-direction: column; padding:40px 0 0 0}
	.landing-layout .screen2 .col3 .cart {width: 100%; padding: 20px 25px; margin:0 0 11px 0}
	.landing-layout .screen2 .col3 .cart .description br{display:none}
	.landing-layout .benefits>div{background-size: 33px 33px}
	.landing-layout .button .bluebtn{margin:0}
	.landing-layout .benefits {padding: 15px 0 30px 0;}
	.landing-layout .calcform input.text{margin:0 0 10px 0}
	.landing-layout .calcform input.text, .calcform .redbtn{line-height: 60px; height: 60px;}
	.landing-layout .total .bonus {font-size: 22px; line-height: 24px;}
	.landing-layout .total {padding: 25px 0 0 0;}
	.landing-layout .numberline .wrapper>div {position: relative; width:100%!important; padding: 0 0 30px 55px!important;}
	
	.landing-layout .numberline .wrapper{counter-reset: list;}
	.landing-layout .numberline .wrapper>div::before {content: counter(list);counter-increment: list; position:absolute; left:0; top:0;
	display:block; width: 40px; height: 40px; line-height:40px; background: #3E4569; border-radius:100%; text-align:center}
	
	
}	