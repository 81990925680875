.wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;

  .standardFormContainer {
    position: relative;
    width: 750px;
    height: auto;
    min-height: 250px;
    margin: 36px auto;
    padding: 32px 48px;
    background-color: #fff;
    box-shadow: 0 4px 10px 4px rgba(34, 60, 80, 0.1);
    border-radius: 6px;

    .title {
      margin-bottom: 32px;
      font-size: 24px;
      font-weight: 600;
    }
  }
}
